import React, { useState } from "react";
import { Button, List, Space } from "antd";
import { gql, useMutation } from "@apollo/client";
import find from "lodash/fp/find";
import { CustomForm } from "@Components/Form";
import { CustomModal } from "@Components/Modals/Modal";
import ViewRecipe from "@Components/Modals/ViewRecipe";
import { useAdminPage } from "@Hooks/useAdminPage";
import { useIngredientsAdmin } from "@GraphQL/GetIngredientsAdmin";
import { useAddRecipeIngredient } from "@GraphQL/UpdateRecipeIngredient";
import { useTags } from "@GraphQL/GetTags";
import {
  ContainerOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";

const listQuery = gql`
  query RecipesAdmin {
    recipes_admin {
      id
      name
      cookTime
      prepTime
      ingredients {
        id
        amount
        unit
        ingredient {
          name
        }
      }
      instructions
      published
      image
      tags {
        id
        name
      }
    }
  }
`;

const updateMutation = gql`
  mutation UpdateRecipe($id: ID!, $input: RecipeInput!) {
    update_recipe(id: $id, input: $input) {
      id
    }
  }
`;

const addMutation = gql`
  mutation CreateRecipe($input: RecipeInput!) {
    create_recipe(input: $input) {
      id
    }
  }
`;

const removeIngredientMutation = gql`
  mutation DeleteRecipe($ingredientId: ID!) {
    remove_recipe_ingredient(ingredient_id: $ingredientId)
  }
`;

const ingredientFields = (ingredients = []) => [
  {
    type: "input",
    name: "amount",
    label: "Ingredient",
    placeholder: "Amount",
  },
  {
    type: "select",
    name: "ingredientId",
    placeholder: "Ingredient",
    options: ingredients.map(({ name, id }) => ({ label: name, value: id })),
    showSearch: true,
    filter: (input, option) =>
      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0,
  },
];

const updateFields = (tags = []) => [
  {
    type: "input",
    name: "name",
    label: "Name",
    placeholder: "Name",
  },
  {
    type: "textarea",
    name: "instructions",
    label: "Instructions (Markdown)",
    placeholder: "Instructions",
  },
  {
    type: "number",
    name: "cookTime",
    label: "Cook Time",
  },
  {
    type: "number",
    name: "prepTime",
    label: "Prep Time",
  },
  {
    type: "checkbox",
    label: "Published",
    name: "published",
  },
  {
    type: "multi-select",
    name: "tags",
    placeholder: "tags",
    options: tags.map(({ name, id }) => ({ label: name, value: id })),
    showSearch: true,
    filter: (input, option) =>
      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0,
  },
  {
    type: "image",
    name: "image",
    label: "Primary Image",
  },
];

const columns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "Published",
    dataIndex: "published",
    render: (text, record) => {
      return text ? (
        <CheckCircleOutlined style={{ color: "#56a3a6ff" }} />
      ) : (
        <CloseCircleOutlined style={{ color: "#fb4b4eff" }} />
      );
    },
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Cook Time",
    dataIndex: "cookTime",
    key: "cookTime",
  },
  {
    title: "Prep Time",
    dataIndex: "prepTime",
    key: "prepTime",
  },
];

const addFields = [
  {
    type: "input",
    name: "name",
    label: "Name",
    placeholder: "Name",
  },
  {
    type: "number",
    name: "cookTime",
    label: "Cook Time",
  },
  {
    type: "number",
    name: "prepTime",
    label: "Prep Time",
  },
  {
    type: "checkbox",
    label: "Published",
    name: "published",
  },
];

const RecipeIngredientList = ({
  recipeIngredients = [],
  onIngredientSubmit,
  ingredients,
}) => {
  const [removeIngredient] = useMutation(removeIngredientMutation, {
    refetchQueries: [{ query: listQuery }],
  });
  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <CustomForm
        name="ingredient-subform"
        onSubmit={onIngredientSubmit}
        fields={ingredientFields(ingredients)}
        layout="inline"
      />
      <List
        bordered
        dataSource={recipeIngredients}
        renderItem={({ id, ingredient: { name = "" }, amount = "" }) => (
          <List.Item
            actions={[
              <Button
                key="ingredient-remove"
                shape="circle"
                icon={<CloseCircleOutlined />}
                onClick={() => {
                  removeIngredient({ variables: { ingredientId: id } });
                }}
              />,
            ]}
          >
            {amount} {name}
          </List.Item>
        )}
      />
    </Space>
  );
};

const Recipes = () => {
  const { ingredients = [] } = useIngredientsAdmin();
  const { tags = [] } = useTags();
  const [editingIngredients, setEditingIngredients] = useState(false);
  const [preview, setPreview] = useState();
  const [addIngredient] = useAddRecipeIngredient({
    refetchQueries: [{ query: listQuery }],
  });

  const onIngredientSubmit = (input, id) => {
    console.log(id);
    addIngredient({ variables: { input: { ...input, recipeId: id } } });
  };

  const [{ data, selectedId, refetch }, AdminPageLayout] = useAdminPage({
    listQuery,
    addMutation,
    updateMutation,
    dataPath: "recipes_admin",
    defaultSelectedKey: "Recipes",
    itemName: "Recipe",
    columns,
    addFields,
    updateFields: updateFields(tags),
    ExtraActions: ({ setSelected }) => (
      <Space>
        <Button
          shape="circle"
          icon={<ContainerOutlined />}
          onClick={() => {
            setEditingIngredients(true);
            setSelected();
          }}
        />
        <Button
          shape="circle"
          icon={<ContainerOutlined />}
          onClick={() => {
            setPreview(true);
            setSelected();
          }}
        />
      </Space>
    ),
  });
  console.log(find({ id: selectedId }, data));
  return (
    <>
      {AdminPageLayout}
      <CustomModal
        isModalOpen={editingIngredients}
        closeModal={() => setEditingIngredients(false)}
        name="Edit Ingredients"
      >
        <RecipeIngredientList
          recipeIngredients={find({ id: selectedId }, data)?.ingredients}
          onIngredientSubmit={(input) => onIngredientSubmit(input, selectedId)}
          ingredients={ingredients}
          refetch={refetch}
        />
      </CustomModal>
      <ViewRecipe
        isModalOpen={preview}
        closeModal={() => setPreview(false)}
        recipe={find({ id: selectedId }, data)}
      />
    </>
  );
};

export { Recipes };

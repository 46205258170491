import React from "react";
import { Table } from "antd";

const AdminTable = ({ data, columns }) => {
  return (
    <Table
      columns={columns}
      dataSource={data.map((item, index) => ({ ...item, key: index }))}
    />
  );
};

export { AdminTable };

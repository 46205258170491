import React, { useEffect, useState } from "react";
import noop from 'lodash/fp/noop';
import { CustomForm as Form } from "@Components/Form";
import { CustomModal } from "@Components/Modals/Modal";
import "./FormModal.scss";

const FormModal = ({
  isModalOpen,
  closeModal,
  title,
  onSubmit = noop,
  fields = [],
  defaultValues = {},
  onSubmitFailed = noop,
  formName = '',
  CustomForm = null,
  HeaderComponent = () => <div />,
  FooterComponent = () => <div />,
}) => {
  return (
    <CustomModal isModalOpen={isModalOpen} closeModal={closeModal} name={title}>
      <HeaderComponent />
      {CustomForm ? <CustomForm /> : (
        <Form
          name={formName}
          onSubmit={onSubmit}
          onSubmitFailed={onSubmitFailed}
          fields={fields}
          defaultValues={defaultValues}
        />
      )}
      <FooterComponent />
    </CustomModal>
  );
};

export { FormModal };

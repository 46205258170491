import React from "react";
import ReactDOM from "react-dom";

import App from "./containers/Home/Home";
import { Auth0Provider } from "@auth0/auth0-react";
import Modal from 'react-modal';
import "antd/dist/antd.less";
import "./index.css";
Modal.setAppElement('#root');

ReactDOM.render(
  <Auth0Provider
    domain="gotsoup.us.auth0.com"
    clientId="ZgxgHy0A3pnnJlo5MPhqPOEHjuItot38"
    redirectUri={window.location.origin}
    audience="https://gotsoup.us.auth0.com/api/v2/"
    scope="read:current_user update:current_user_metadata"
  >
    <App />
  </Auth0Provider>,
  document.getElementById('root')
);

import React from "react";
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
} from "@apollo/client";
import { createLink } from "apollo-absinthe-upload-link";
import { setContext } from "@apollo/client/link/context";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { BrowserRouter, Switch, Route} from "react-router-dom";
import Cookies from "js-cookie";


import Admin from "@Containers/Admin/Admin";
import { Users } from "@Containers/Users";
import { Recipes } from "@Containers/Recipes";
import {Ingredients} from '@Containers/Ingredients';
import {Tags} from '@Containers/Tags';
import Login from '@Containers/Login/Login';

const httpLink = createLink({
  uri: `${process.env.REACT_APP_BASE_URL}/api`,
});

const authLink = setContext(async (_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = Cookies.get("recipe-box-auth-token");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

const AdminRoute = ({ component, ...rest }) => {
  return <Route component={withAuthenticationRequired(component)} {...rest} />;
};

const App = () => (
  <BrowserRouter>
    <ApolloProvider client={client}>
      <Switch>
        <Route exact component={Login} path="/">
        </Route>
        <AdminRoute exact component={Admin} path="/admin" />
        <AdminRoute exact component={Recipes} path="/admin/recipes" />
        <AdminRoute exact component={Ingredients} path="/admin/ingredients" />
        <AdminRoute exact component={Users} path="/admin/users" />
        <AdminRoute exact component={Tags} path="/admin/tags" />
      </Switch>
    </ApolloProvider>
  </BrowserRouter>
);

export default App;

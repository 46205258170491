import React from "react";
import { gql } from "@apollo/client";
import { useAdminPage } from "@Hooks/useAdminPage";

const listQuery = gql`
  query Users {
    users {
      id
      email
      role
    }
  }
`;

const updateMutation = gql`
  mutation UpdateUser($id: ID!, $input: UserInput!) {
    update_user(id: $id, input: $input)
  }
`;

const addMutation = gql`
  mutation CreateUser($input: UserInput!) {
    create_user(input: $input)
  }
`;

const updateFields = [
  {
    type: 'select',
    value: 'role',
    placeholder: 'Role',
    options: [
      {
        label: 'User',
        value: 'user',
      },
      {
        label: 'Admin',
        value: 'admin',
      },
    ],
  },
];

const columns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "Role",
    dataIndex: "role",
    key: "role",
  },
];

const Users = () => {
  const [_, AdminPageLayout] = useAdminPage({
    listQuery,
    addMutation,
    updateMutation,
    dataPath: "users",
    defaultSelectedKey: "Users",
    itemName: "User",
    columns,
    updateFields,
  });

  return <>{AdminPageLayout}</>;
};

export { Users };

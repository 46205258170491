import {gql, useMutation} from '@apollo/client';

const query = gql`
  mutation AddRecipeIngredient($input: RecipeIngredientInput!) {
    add_recipe_ingredient(input: $input) {
      id
      ingredients {
        unit
        amount
        ingredient {
          name
        }
      }
    }
  }
`;

const useAddRecipeIngredient = (props = {}) => useMutation(query, props);

export {query, useAddRecipeIngredient};

import { gql, useQuery } from "@apollo/client";

const query = gql`
query RecipesAdmin {
  recipesAdmin {
    id
  }
}
`;

const useRecipes = (props = {}) => {
  const {data: {recipesAdmin: recipes = []} = {}, ...rest} = useQuery(
    query,
    props
  );
  return {recipes, ...rest};
};

export {query, useRecipes};

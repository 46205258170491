import {gql, useQuery} from '@apollo/client';

const query = gql`
  query GetIngredients {
    ingredients_admin {
      id
      name
      energy
      amount
      unit
    }
  }
`;

const useIngredientsAdmin = (props = {}) => {
  const {data: {ingredients_admin: ingredients = []} = {}, ...rest} = useQuery(
    query,
    props
  );
  return {ingredients, ...rest};
};

export {query, useIngredientsAdmin};

import {gql, useMutation} from '@apollo/client';

const query = gql`
  mutation CreateIngredient($input: IngredientInput!) {
    create_ingredient(input: $input) {
      id
    }
  }
`;

const useCreateIngredient = (props) => useMutation(query, props);

export {query, useCreateIngredient};
import React from "react";
import {gql} from '@apollo/client';
import { useAdminPage } from "@Hooks/useAdminPage";


const listQuery = gql`
  query Tags {
    tagsAdmin {
      id
      name
    }
  }
`;

const addMutation = gql`
  mutation CreateTag($input: TagInput!) {
    create_tag(input: $input) {
      id
    }
  }
`;

const updateMutation = gql`
  mutation UpdateTag($id: ID!, $input: TagInput!) {
    update_tag(id: $id, input: $input) {
      id
    }
  }
`;

const columns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
];

const updateFields = [
  {
    type: "input",
    name: "name",
    label: "Name",
    placeholder: "Name",
  },
];

const addFields = [
  {
    type: "input",
    name: "name",
    label: "Name",
    placeholder: "Name",
  },
];

const Tags = () => {
  const [_, AdminPageLayout] = useAdminPage({
    listQuery,
    addMutation,
    updateMutation,
    dataPath: "tagsAdmin",
    defaultSelectedKey: "Tags",
    itemName: "Tag",
    columns,
    addFields,
    updateFields,
  });

  return <>{AdminPageLayout}</>;
};

export { Tags };
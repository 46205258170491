import React, { useEffect } from "react";
import { useAuth0, User } from "@auth0/auth0-react";
import { Card, Space, Statistic, Typography } from "antd";
import Cookies from "js-cookie";
import { AdminPage } from "@Components/AdminPage";
import { useIngredientsAdmin } from "@GraphQL/GetIngredientsAdmin";
import { useRecipes } from "@GraphQL/GetRecipes";
import { useTags } from "@GraphQL/GetTags";

const { Title } = Typography;

const saveUserData = async (token) => {
  const { href = "" } = window?.location;
  if (href.includes("localhost") || href.includes("about:blank")) {
    Cookies.set("recipe-box-auth-token", token);
  } else {
    Cookies.set("recipe-box-auth-token", token, { secure: true });
  }
};

const UserStats = () => {
  const { tags = [] } = useTags();
  const { ingredients = [] } = useIngredientsAdmin();
  const { recipes = [] } = useRecipes();
  return (
    <Space>
      <Card>
        <Statistic title="Recipes" value={recipes?.length} precision={0} />
      </Card>
      <Card>
        <Statistic
          title="Ingredients"
          value={ingredients.length}
          precision={0}
        />
      </Card>
      <Card>
        <Statistic title="Users" value={tags.length} precision={0} />
      </Card>
    </Space>
  );
};

const Admin = () => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const getUserMetadata = async () => {
      const token = Cookies.get("recipe-box-auth-token");
      if (!token) {
        const accessToken = await getAccessTokenSilently();
        saveUserData(accessToken);
      }
    };

    getUserMetadata();
  }, []);
  console.log(isAuthenticated);
  return (
    <>
      {isAuthenticated ? (
        <AdminPage defaultSelectedKey="Home">
          <Title>Overview</Title>
          <UserStats />
        </AdminPage>
      ) : (
        <div>Loading...</div>
      )}
    </>
  );
};

export default Admin;

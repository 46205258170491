import React, { useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { Button, Space, Typography } from "antd";
import { EditOutlined } from "@ant-design/icons";
import getOr from "lodash/fp/getOr";
import find from "lodash/fp/find";
import noop from "lodash/fp/noop";
import { AdminTable } from "@Components/AdminTable";
import { AdminPage } from "@Components/AdminPage";
import { FormModal } from "@Components/Modals/FormModal";

const { Title } = Typography;

const defaultActions = (onEdit, setSelected, ExtraActions = () => <div />) => [
  {
    title: "Actions",
    key: "actions",
    render: (text, record) => (
      <Space>
        <Button
          shape="circle"
          icon={<EditOutlined />}
          onClick={() => onEdit(record)}
        />
        <ExtraActions setSelected={() => setSelected(record)} />
      </Space>
    ),
  },
];

const AdminPageLayout = ({
  columns,
  data,
  itemName,
  defaultSelectedKey,
  onAdd = noop,
  onUpdate = noop,
  addFields = [],
  updateFields = [],
  currentItem,
  onAddSubmitFailure = noop,
  onUpdateSubmitFailure = noop,
  addIsOpen,
  setAddIsOpen,
  updateIsOpen,
  setUpdateIsOpen,
  showAdd,
  customAddModal = null,
  customUpdateModal = null,
  customUpdateHeader,
  customUpdateFooter,
}) => {
  return (
    <AdminPage defaultSelectedKey={defaultSelectedKey}>
      <Space direction="vertical" style={{ width: "100%" }}>
        <Title>{defaultSelectedKey}</Title>
        {showAdd && (
          <Button type="primary" onClick={() => setAddIsOpen(true)}>
            Create {itemName}
          </Button>
        )}

        <AdminTable data={data} columns={columns} />
        {updateIsOpen && (
          <FormModal
            isModalOpen={updateIsOpen}
            closeModal={() => setUpdateIsOpen(false)}
            title={`Update ${itemName}`}
            onSubmit={(input) => {
              console.log(input);
              const formInput = { ...input };
              if (input?.image) formInput.image = input?.image?.file;
              onUpdate({
                variables: { id: currentItem?.id, input: formInput },
              });
            }}
            fields={updateFields}
            defaultValues={currentItem}
            onSubmitFailed={onUpdateSubmitFailure}
            formName={`update-${itemName}`}
            CustomForm={customUpdateModal}
            HeaderComponent={customUpdateHeader}
            FooterComponent={customUpdateFooter}
          />
        )}
        <FormModal
          isModalOpen={addIsOpen}
          closeModal={() => setAddIsOpen(false)}
          title={`Add ${itemName}`}
          onSubmit={(input) => onAdd({ variables: { input } })}
          fields={addFields}
          onSubmitFailed={onAddSubmitFailure}
          formName={`add-${itemName}`}
          CustomForm={customAddModal}
        />
      </Space>
    </AdminPage>
  );
};

const useAdminPage = ({
  listQuery,
  addMutation,
  updateMutation,
  dataPath,
  defaultSelectedKey,
  itemName,
  columns,
  addFields = [],
  updateFields = [],
  showAdd = true,
  customAddModal = null,
  customUpdateModal = null,
  customUpdateHeader,
  customUpdateFooter,
  ExtraActions,
}) => {
  const { data, refetch } = useQuery(listQuery);
  const [selected, setSelected] = useState(null);
  const [updateIsOpen, setUpdateIsOpen] = useState(false);
  const [addIsOpen, setAddIsOpen] = useState(false);

  const [addItem] = useMutation(addMutation, {
    onCompleted: () => {
      refetch();
      setAddIsOpen(false);
    },
  });
  const [updateItem] = useMutation(updateMutation, {
    onCompleted: () => {
      refetch();
      setUpdateIsOpen(false);
    },
  });

  const onEdit = (item) => {
    console.log(item);
    setSelected(item);
    setUpdateIsOpen(true);
  };

  return [
    { data: getOr([], dataPath, data), refetch, selectedId: selected?.id },
    <AdminPageLayout
      defaultSelectedKey={defaultSelectedKey}
      itemName={itemName}
      data={getOr([], dataPath, data)}
      columns={[
        ...columns,
        ...defaultActions(onEdit, setSelected, ExtraActions),
      ]}
      onAdd={addItem}
      onUpdate={updateItem}
      addFields={addFields}
      updateFields={updateFields}
      currentItem={selected}
      updateIsOpen={updateIsOpen}
      addIsOpen={addIsOpen}
      setAddIsOpen={setAddIsOpen}
      setUpdateIsOpen={setUpdateIsOpen}
      showAdd={showAdd}
      customAddModal={customAddModal}
      customUpdateModal={customUpdateModal}
      customUpdateHeader={customUpdateHeader}
      customUpdateFooter={customUpdateFooter}
    />,
  ];
};

export { useAdminPage };

const styles = {
  bodyLayout: {
    minHeight: '100vh',
  },
  header: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingHorizontal: 10,
  },
  content: {
    backgroundColor: '#e9ecf5ff',
    padding: '1rem',
  },
  menu: {
    marginTop: 80,
  }
};

export default styles;

import {gql, useMutation} from '@apollo/client';

const query = gql`
  mutation UpdateIngredient($id: ID!, $input: IngredientInput!) {
    update_ingredient(id: $id, input: $input) {
      id
    }
  }
`;

const useCreateIngredient = (props) => useMutation(query, props);

export {query, useCreateIngredient};
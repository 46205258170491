import React from 'react';
import {Modal} from 'antd';
import './Modal.scss';

const CustomModal = ({isModalOpen, closeModal, children, name}) => {
  return (
    <Modal
      visible={isModalOpen}
      onCancel={closeModal}
      title={name}
      footer={null}
      width={600}
    >
      {children}
    </Modal>
  );
};

export {CustomModal};

import React from "react";
import { query as listQuery } from "@GraphQL/GetIngredientsAdmin";
import { query as addMutation } from "@GraphQL/CreateIngredient";
import { query as updateMutation } from "@GraphQL/UpdateIngredient";
import { useAdminPage } from "@Hooks/useAdminPage";

const columns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Energy",
    dataIndex: "energy",
    key: "energy",
  },
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
  },
  {
    title: "Unit",
    dataIndex: "unit",
    key: "unit",
  },
];

const updateFields = [
  {
    type: "input",
    name: "name",
    label: "Name",
    placeholder: "Name",
  },
  {
    type: "number",
    name: "energy",
    label: "Energy",
  },
  {
    type: "number",
    name: "amount",
    label: "Amount",
  },
  {
    type: "select",
    name: "unit",
    placeholder: "Unit",
    options: [
      { label: "g", value: "gram" },
      { label: "ml", value: "milliliter" },
    ],
  },
];

const addFields = [
  {
    type: "input",
    name: "name",
    label: "Name",
    placeholder: "Name",
  },
  {
    type: "number",
    name: "energy",
    label: "Energy",
  },
  {
    type: "number",
    name: "amount",
    label: "Amount",
  },
  {
    type: "select",
    name: "unit",
    placeholder: "Unit",
    options: [
      { label: "g", value: "gram" },
      { label: "ml", value: "milliliter" },
    ],
  },
];

const Ingredients = () => {
  const [_, AdminPageLayout] = useAdminPage({
    listQuery,
    addMutation,
    updateMutation,
    dataPath: "ingredients_admin",
    defaultSelectedKey: "Ingredients",
    itemName: "Ingredient",
    columns,
    addFields,
    updateFields,
  });

  return <>{AdminPageLayout}</>;
};

export { Ingredients };

import {gql, useQuery} from '@apollo/client';

const query = gql`
query Tags {
  tagsAdmin {
    id
    name
  }
}
`;

const useTags = (props = {}) => {
  const {data: {tagsAdmin: tags = []} = {}, ...rest} = useQuery(
    query,
    props
  );
  return {tags, ...rest};
};

export {query, useTags};
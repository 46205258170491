import React from "react";
import { Space, Tag, Typography } from "antd";
import ReactMarkdown from "react-markdown";
import { CustomModal } from "@Components/Modals/Modal";
import "./ViewRecipe.css";

const { Text, Title } = Typography;

const ViewRecipe = ({ isModalOpen, closeModal, recipe = {} }) => {
  console.log(recipe);
  const { name, image, ingredients = [], instructions } = recipe;
  return (
    <CustomModal
      isModalOpen={isModalOpen}
      closeModal={closeModal}
      name="View Recipe"
    >
      <Space direction="vertical">
        {image && <img src={image} alt={name} />}
        <Title>{name}</Title>
        <Title level={4}>Ingredients</Title>
        <Space wrap direction="vertical">
          {ingredients.map(({ amount, ingredient = {} }) => (
            <Text>
              <Tag color="#56a3a6ff">{amount}</Tag>
              {ingredient?.name}
            </Text>
          ))}
        </Space>
        <Title level={4}>Instructions</Title>
        <ReactMarkdown
          components={{
            ol: ({ node, ...props }) => (
              <ol {...props} className="custom-ordered" />
            ),
            li: ({ children, node, ...props }) => {
              console.log(node, props);
              return <li {...props} className="custom-list-number">{<span className="custom-list-item">{children[0]}</span>}</li>;
            },
          }}
        >
          {instructions}
        </ReactMarkdown>
      </Space>
    </CustomModal>
  );
};

export default ViewRecipe;

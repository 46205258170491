import React from "react";
import classNames from "classnames";
import "./Logo.css";

const Logo = ({ light = false, small = false }) => (
  <span
    className={classNames({
      "logo-light": light,
      "logo-dark": !light,
      "logo-small": small,
    })}
  >
    <span className="logo-highlight">my</span>recipebox
  </span>
);

export default Logo;

import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useHistory } from "react-router-dom";
import { Button, Card } from "antd";
import Logo from "@Components/Logo";
import { ReactComponent as RecipeBox } from "@Assets/RecipeBox.svg";

import "./Login.css";

const Login = () => {
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();
  const history = useHistory();

  useEffect(() => {
    if (!isLoading && isAuthenticated) {
      history.push("/admin");
    }
  }, [history, isLoading, isAuthenticated]);

  return (
    <div className="background">
      <Card
        style={{ width: 400 }}
        bodyStyle={{ alignItems: 'center', display: "flex", flexDirection: "column" }}
      >
        <RecipeBox />
        <Logo />
        <Button block type="primary" onClick={loginWithRedirect} style={{marginTop: 25}}>
          Login
        </Button>
      </Card>
    </div>
  );
};

export default Login;

import React, { useState } from "react";
import find from "lodash/fp/find";
import { useAuth0 } from "@auth0/auth0-react";
import { Layout, Menu } from "antd";
import {
  BookOutlined,
  CoffeeOutlined,
  HomeOutlined,
  LogoutOutlined,
  TeamOutlined,
  TagsOutlined,
} from "@ant-design/icons";

import Logo from "@Components/Logo";

import { useHistory } from "react-router-dom";
import styles from "./styles";

const { Header, Content, Sider } = Layout;

const MenuItem = Menu.Item;

const menuOptions = [
  { label: "Home", icon: <HomeOutlined />, path: "/admin" },
  { label: "Recipes", icon: <BookOutlined />, path: "/admin/recipes" },
  {
    label: "Ingredients",
    icon: <CoffeeOutlined />,
    path: "/admin/ingredients",
  },
  { label: "Users", icon: <TeamOutlined />, path: "/admin/users" },
  { label: "Tags", icon: <TagsOutlined />, path: "/admin/tags" },
  { label: "Logout", icon: <LogoutOutlined /> },
];

const AdminPage = ({ defaultSelectedKey = "Recipes", children }) => {
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const { logout } = useAuth0();
  return (
    <Layout style={styles.bodyLayout}>
      <Sider
        style={styles.slider}
        collapsible
        collapsed={open}
        onCollapse={() => setOpen(!open)}
      >
        <Menu
          mode="inline"
          defaultSelectedKeys={[defaultSelectedKey]}
          theme="dark"
          onClick={({ key }) => {
            const option = find({ label: key }, menuOptions);
            if (option?.path) {
              history.push(option?.path);
            } else {
              logout();
            }
          }}
          style={styles.menu}
        >
          {menuOptions.map(({ label, icon }) => (
            <MenuItem key={label} icon={icon}>
              {label}
            </MenuItem>
          ))}
        </Menu>
      </Sider>
      <Layout>
        <Header style={styles.header}>
          <Logo light small />
        </Header>
        <Content style={styles.content}>{children}</Content>
      </Layout>
    </Layout>
  );
};

export { AdminPage };
